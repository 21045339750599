<template>
  <div class="window window-restyle absolute top-0 right-0 mt-[80px] mr-[15px] mb-[15px]" style="z-index: 80">
    <main class="window-background w-[345px]" style="flex-grow: 0;">
      <header class="window-title flex items-center">
				<span v-if="editorStore.selected.length === 1" class="window-name font-normal flex-1" style="line-height: 1.3">
					{{ editorStore.selected[0]?.component?.name || 'Параметры' }}
				</span>

        <a class="button button-transparent  top-0 right-0 m-[5px]" @click="closeParams(1)">
          <Icon name="cross"></Icon>
        </a>
      </header>
      <div class="window-content overflow-y-auto space-y-[15px] max-h-[75vh]" style="flex: auto; flex-shrink: 1"
           v-if="editorStore.selected.length === 1" @wheel.stop>
        <div class="opacity-80 leading-[1.3]">
          {{ editorStore.selected[0]?.component?.description }}
        </div>

        <div class="flex flex-row space-x-[5px] items-center">
          <Icon name="cog" color="black"></Icon>
          <span class="fieldname w-[150px]" style="flex-shrink: 0"> Тип </span>
          <value class="">{{ editorStore.selected[0]?.component?.category?.name }}</value>
        </div>
        <div class="flex flex-row space-x-[5px] items-center">
          <Icon name="cog" color="black"></Icon>
          <span class="fieldname w-[150px]" style="flex-shrink: 0"> Размер </span>
          <value class="">{{ editorStore.selected[0]?.component?.width }} x {{
              editorStore.selected[0]?.component?.height
            }}
          </value>
        </div>


        <!--        -->

        <template v-for="parameter in editorStore.selected[0]?.component?.category?.parameters" :key="parameter.id">
          <div class="flex flex-row space-x-[5px] items-center" >
            <Icon name="cog" color="black"></Icon>
            <span class="fieldname w-[150px] whitespace-nowrap text-ellipsis overflow-hidden" style="flex-shrink: 0"> {{ parameter.name }} </span>
            <value v-if="parameter.type=='BOOLEAN'" class="">{{editorStore.selected[0]?.component?.parameters[parameter.id] ? 'да' : 'нет'}}</value>
            <value v-else class="">{{ editorStore.selected[0]?.component?.parameters[parameter.id] }} {{parameter.unit ? parameter.unit  : ''}}</value>
          </div>
        </template>

      </div>
    </main>
  </div>
</template>

<script setup>
import {computed} from 'vue';
import {usePalleteStore} from "@/stores/pallete";
import {useEditorStore} from "@/stores/editor";
import Icon from "@/components/Icon"; // Replace with actual import if needed

const palleteStore = usePalleteStore()
const editorStore = useEditorStore()

const closeParams = (val) => {
  // Replace with actual implementation
};

</script>


<style scoped></style>