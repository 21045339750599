<template>
  <div
    id="editor"
    class="absolute inset-0 z-0 editor"
    :style="editorComputedStyles"
    oncontextmenu="return false;"
    @pointerdown.stop="handleEditorPointerDown"
    @touchstart.stop="handleEditorPointerDown"

  >
    <!--    <div @pointerdown.stop class="absolute bg-white w-[300px] min-h-[100px] bottom-[100px] left-[10px] flex flex-col p-2">-->
    <!--      <label for="intervalInput">Select a value (0.1 to 10, step 0.1):</label>-->
    <!--      <input type="range" id="intervalInput" name="intervalInput" min="0.1" max="2" step="0.01" v-model="editorStore.scale">-->
    <!--      <output id="output">{{ editorStore.scale }}</output>-->
    <!--      <hr>-->
    <!--      <div>-->
    <!--        Offset X: {{editorStore.offsetX}}-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        Offset Y: {{editorStore.offsetY}}-->
    <!--      </div>-->
    <!--      <hr>-->
    <!--      <div v-if="editorStore.objects.length > 0">-->
    <!--        <div>-->
    <!--          Object X: {{editorStore.objects[0].left}}-->
    <!--        </div>-->
    <!--        <div>-->
    <!--          Object Y: {{editorStore.objects[0].top}}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <div>-->
    <!--          Mode: {{editorStore.mode}}-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div>-->
    <!--        <div>Ports</div>-->
    <!--        <div>{{allPorts}}</div>-->
    <!--      </div>-->
    <!--      <button class="button button-positive w-min h-[40px] clipshape-all" @click="validatePorts">-->
    <!--        <span> Validate </span>-->
    <!--      </button>-->
    <!--      <div>-->
    <!--        <div>Intersections Ports</div>-->
    <!--        <div>{{intersectionPorts}}</div>-->
    <!--      </div>-->

    <!--    </div>-->
    <div class="absolute inset-0 pointer-events-none opacity-100 editor-circles" :style="circlesComputedStyles"></div>

    <div class="absolute"
         :style="{filter: editorStore.dragdrop.object ? null : `drop-shadow(0 0 10px rgba(0, 0, 0, 0.5))`}">
      <template
        v-for="object in editorStore.objects"
        :key="object.id"
      >
        <div class="editor-item">
          <div
              v-if="editorStore.mode !== editorModes.objectMove"
              class="editor-item-remove absolute z-[10000] w-[25px] h-[25px]"
               :style="{left: `${(object.left + editorStore.offsetX / editorStore.scale) * editorStore.scale}px`, top: `${(object.top + editorStore.offsetY / editorStore.scale) * editorStore.scale}px`}"
          >
            <span class="absolute trans" @click.stop @pointerdown.stop style="top: 0; left: 0;">
              <a class="button button-remove sm flex items-center justify-center" style="margin: -5px;"
                 @click="removeObject(object)"
              >
                <Icon name="trash"></Icon>
              </a>
              </span>
          </div>

          <div v-if="editorStore.mode !== editorModes.objectMove"
               class="editor-item-remove absolute z-[10000] w-[25px] h-[25px] transition-all"
               :style="{left: `${(object.left + ((object.rotation % 2 === 0 ? object.component.width : object.component.height)*25) + editorStore.offsetX / editorStore.scale) * editorStore.scale}px`, top: `${(object.top + editorStore.offsetY / editorStore.scale) * editorStore.scale}px`}"
          >
            <span class="absolute trans" @click.stop @pointerdown.stop style="left: -10px;">
              <a class="button button-remove sm flex items-center justify-center" style="margin: -5px;"
                 @click="turnComponent(object)"
              >
                <Icon name="turn"></Icon>
              </a>
            </span>
          </div>

          <EditorObject

            :model-value="object"
            @update:modelValue="Object.assign(object, $event)"
            @left-button-down.stop="leftClickOnObject(object, $event)"
            @mouseup.stop="selectObject(object)"
            @rotate="rotateHandler"

          ></EditorObject>
        </div>


      </template>


    </div>
    <InfoPanel></InfoPanel>
    <HintPanel></HintPanel>
  </div>
</template>

<script setup>

import {computed, nextTick, onMounted, onUnmounted, ref, watch} from "vue";
import {useEditorStore, editorModes} from "@/stores/editor";
import EditorObject from "@/components/EditorObject";
import {usePalleteStore} from "@/stores/pallete";
import InfoPanel from "@/components/InfoPanel";
import HintPanel from "@/components/HintPanel";
import Icon from "@/components/Icon";
import {usePopUpsStore} from "@/stores/popups";

const {v4: uuidv4} = require('uuid');

const editorStore = useEditorStore()
const palleteStore = usePalleteStore()
const popupsStore = usePopUpsStore()

function turnComponent(object) {
  object.rotation++
  nextTick(() => {
    validatePorts()
  })
}

const rotateHandler = () => {
  setTimeout(() => {validatePorts()}, 500)
}

function getTransformedElementCenter(element) {
  const rect = element.getBoundingClientRect();

  // Вычисляем центр элемента без учета трансформации
  let centerX = rect.left + rect.width / 2;
  let centerY = rect.top + rect.height / 2;

  // Получаем текущие стили элемента
  const computedStyle = window.getComputedStyle(element);
  const transform = computedStyle.transform;

  if (transform && transform !== 'none') {
    // Разбираем матрицу трансформации
    const transformMatrix = new DOMMatrix(transform);

    // Применяем трансформации к центру элемента
    const transformedCenter = transformMatrix.transformPoint({
      x: centerX - rect.left,
      y: centerY - rect.top
    });

    centerX = transformedCenter.x + rect.left;
    centerY = transformedCenter.y + rect.top;
  }

  return {centerX, centerY};
}

function checkIntersection(element1, radius1, element2, radius2) {
  const center1 = getTransformedElementCenter(element1);
  const center2 = getTransformedElementCenter(element2);

  const distanceX = center1.centerX - center2.centerX;
  const distanceY = center1.centerY - center2.centerY;
  const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);

  return distance < (radius1 + radius2);
}


const allPorts = computed(() => {
  const ports = []
  for (const object of editorStore.objects) {
    ports.push(...object.component.ports)
  }
  return ports
})

// Функция для получения элементов с атрибутом component-port
function getComponentPortElements() {
  return [...document.querySelectorAll('[component-port]')];
}

// Функция для проверки пересечения двух прямоугольников
function isIntersecting(rect1, rect2) {
  return !(rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom);
}

// Функция для возврата списка пар пересекающихся элементов
function getIntersectingPairs() {
  const elements = getComponentPortElements();
  const pairs = [];

  for (let i = 0; i < elements.length; i++) {
    for (let j = i + 1; j < elements.length; j++) {
      const rect1 = elements[i].getBoundingClientRect();
      const rect2 = elements[j].getBoundingClientRect();

      if (isIntersecting(rect1, rect2)) {
        pairs.push([elements[i], elements[j]]);
      }
    }
  }

  return pairs;
}


const intersectionPorts = ref([])

const validatePorts = () => {
  popupsStore.cartRedirectPortValid.visible = false
  editorStore.portsValid = true
  const elements = getComponentPortElements();
  for (const element of elements) {
    element.style.backgroundColor = 'white'
  }
  intersectionPorts.value = getIntersectingPairs()
  for (const pair of intersectionPorts.value) {
    const e1 = pair[0]
    const e2 = pair[1]
    const e1type = e1.getAttribute("component-port-type")
    const e2type = e2.getAttribute("component-port-type")

    console.log(e1, e2, e1type, e2type)
    let good = true
    if ((e1type === 'OUT') && (e2type === 'OUT')) {
      good = false
    }
    if ((e1type === 'IN') && (e2type === 'IN')) {
      good = false
    }


    if (good) {
      console.log('123', e1.style.backgroundColor)
      e1.style.backgroundColor = 'green'
      e2.style.backgroundColor = 'green'
      console.log('123', e1.style.backgroundColor)
    } else {
      editorStore.portsValid = false
      e1.style.backgroundColor = 'red'
      e2.style.backgroundColor = 'red'
    }



  }

  for (const element of elements) {
    if (element.style.backgroundColor === 'white'){
      editorStore.portsValid = false
    }
  }
  // console.log('Intersection ports: ',  intersectionPorts.value)
}


watch(() => editorStore.mode, () => {
  validatePorts()
})
//
// const intersectionPorts = computed(() => {
//
//   return ports
// })


const editorComputedStyles = computed(() => {
  const s = editorStore.scale
  // const bgImage = `linear-gradient(to right, rgba(52, 54, 54, 0.25) 1px, transparent 1px),
  // linear-gradient(to bottom, rgba(52, 54, 54, 0.25) 1px, transparent 1px),
  // linear-gradient(to right, rgba(52, 54, 54, 0.35) 2px, transparent 1px),
  // linear-gradient(to bottom, rgba(52, 54, 54, 0.35) 2px, transparent 1px),
  // linear-gradient(to right, rgba(52, 54, 54, 0.0) 1px, transparent 1px),
  // linear-gradient(to bottom, rgba(52, 54, 54, 0.0) 1px, transparent 1px)`
  const bgImage = `linear-gradient(to right, rgba(52, 54, 54, 0.25) 1px, transparent 1px),
  linear-gradient(to bottom, rgba(52, 54, 54, 0.25) 1px, transparent 1px),
  linear-gradient(to right, rgba(52, 54, 54, 0.35) 2px, transparent 1px),
  linear-gradient(to bottom, rgba(52, 54, 54, 0.35) 2px, transparent 1px)`
  // const bgSize = `25px 25px,
  // 25px 25px,
  // calc(25px * 16) calc(25px * 16),
  // calc(25px * 16) calc(25px * 16),
  // 20px 20px,
  // 20px 20px`

  const bgSize = `${25 * s}px ${25 * s}px,
  ${25 * s}px ${25 * s}px,
  calc(${25 * s}px * ${16}) calc(${25 * s}px * ${16}),
  calc(${25 * s}px * ${16}) calc(${25 * s}px * ${16})`

  // const bgSize = `${25 * s}px ${25 * s}px,
  // ${25 * s}px ${25 * s}px,
  // calc(${25 * s}px * ${16 * s}) calc(${25 * s}px * ${16 * s}),
  // calc(${25 * s}px * ${16 * s}) calc(${25 * s}px * ${16 * s}),
  // ${20 * s}px ${20 * s}px,
  // ${20 * s}px ${20 * s}px`

  return {
    backgroundPosition: `${editorStore.offsetX}px ${editorStore.offsetY}px`,
    backgroundImage: bgImage,
    backgroundSize: bgSize,
  }
})

const circlesComputedStyles = computed(() => {
  const s = editorStore.scale
  return {
    'backgroundPosition': `${(25 * s) * 8 + 1 + editorStore.offsetX}px ${(25 * s) * 8 + 1 + editorStore.offsetY}px`,
    'backgroundImage': `radial-gradient(circle, rgb(var(--fg), 0.8) ${4 * s}px, transparent 2.5px)`,
    'backgroundSize': `calc(${25 * s}px * 16) calc(${25 * s}px * 16)`,
  }
})


const selectObject = (object) => {
  editorStore.selected = [object]

}

const handleSpace = (event) => {
  console.log('event keydown', event)
  if (event.code === "Space") {
    // if (editorStore.pointer.object) { // Сначала проворачиваем тот на который наведен курсор
    //   editorStore.pointer.object.rotation++
    // }
    // else { // А потом уже все остальное
    if (editorStore.selected.length === 1) {
      editorStore.selected[0].rotation++
      nextTick(() => {
        validatePorts()
      })
    }
    // }
  }
  if (event.code === "Delete") {
    if (editorStore.selected.length === 1) {
      removeObject(editorStore.selected[0])
    }
  }
  if (event.code === "Escape") {
    if ((editorStore.newObject) && (editorStore.selected.length === 1)) {
      removeObject(editorStore.selected[0])
    }
  }
}


const removeObject = (object) => {
  console.log('Remove object: ', object)
  const idx = editorStore.objects.indexOf(object)
  console.log('IDS: ', idx)
  if (idx >= 0) {
    editorStore.objects.splice(idx, 1)
  }
  nextTick(() => {
    validatePorts()
  })

}


const leftClickOnObject = (object, event) => {
  // selectObject(object)
  // object.z = ++editorStore.zCounter
  if (editorStore.mode == null) {
    editorStore.mode = editorModes.objectMove
    editorStore.dragdrop.object = object
    editorStore.dragdrop.startLeft = event.clientX
    editorStore.dragdrop.startTop = event.clientY
    // editorStore.dragdrop.objectStartLeft = object.left
    // editorStore.dragdrop.objectStartTop = object.top

    // editorStore.selected = [object]
    //
    // editorStore.tmpComponent = object.component

    if (!editorStore.selected.includes(object)) {
      editorStore.selected = [object]
    }

    editorStore.dragdrop.objectsStartLeft = []
    editorStore.dragdrop.objectsStartTop = []



    for (const object of editorStore.selected) {
      editorStore.dragdrop.objectsStartLeft.push(
        object.left
      )
      editorStore.dragdrop.objectsStartTop.push(
        object.top
      )
    }

  }
}
const mouseUpObject = (event) => {
  event.stopPropagation()
  // console.log('*** UP')
  editorStore.newObject = false
  if (editorStore.mode === editorModes.objectMove) {
    // selectObject(editorStore.tmpObject)
    editorStore.mode = null
    editorStore.dragdrop.object = null
    event.stopPropagation()
  } else if (editorStore.mode === editorModes.objectPointerDown) {
    editorStore.mode = null
    editorStore.selected = [{'component': editorStore.tmpComponent}]
  } else if (editorStore.mode === editorModes.editorPointerDown) {
    editorStore.mode = null
    palleteStore.form.selected = null
    editorStore.selected = []
  } else if (editorStore.mode === editorModes.editorMove) {
    editorStore.mode = null
  } else if (editorStore.mode === editorModes.selectObjects) {
    editorStore.mode = null
    editorStore.selected = []

    // const bl = editorStore.selectionWindow.left / editorStore.scale
    // const bt = editorStore.selectionWindow.top / editorStore.scale
    const bl = editorStore.selectionWindow.left
    const bt = editorStore.selectionWindow.top
    const br = (editorStore.selectionWindow.left + editorStore.selectionWindow.width)
    const bb = (editorStore.selectionWindow.top + editorStore.selectionWindow.height)

    // {
    //
    //   const editor = document.getElementById('editor')
    //   const el = document.createElement('div')
    //   el.style.backgroundColor = 'red'
    //   el.style.position = 'absolute'
    //   el.style.left = `${bl}px`
    //   el.style.top = `${bt}px`
    //   el.style.width = `${br - bl}px`
    //   el.style.height = `${bb-bt}px`
    //   editor.appendChild(el)
    // }


    for (const object of editorStore.objects) {



      const inRect = (x, y) => {
        return (x >= bl) && (x <= br) && (y >= bt) && (y <= bb)
      }

      const objectInRect = (obj) => {
        const l = (obj.left ) * editorStore.scale + editorStore.offsetX
        const t = (obj.top ) * editorStore.scale + editorStore.offsetY
        const r = l + (obj.component.width * 25 ) * editorStore.scale
        const b = t + (obj.component.height * 25) * editorStore.scale

        // const editor = document.getElementById('editor')
        // const el = document.createElement('div')
        // el.style.backgroundColor = 'green'
        // el.style.position = 'absolute'
        // el.style.left = `${l}px`
        // el.style.top = `${t}px`
        // el.style.width = `${r - l}px`
        // el.style.height = `${b-t}px`
        // editor.appendChild(el)

        return inRect(l, t) || inRect(r, t) || inRect(r, b) || inRect(l, b)
      }

      if (objectInRect(object)){
        editorStore.selected.push(object)
      }

    }

    editorStore.selectionWindow.left = -100000
    editorStore.selectionWindow.top = -100000
    editorStore.selectionWindow.width = 0
    editorStore.selectionWindow.height = 0
  }
}

const mouseMoveHandler = (event) => {
  // console.log('Move: ', event)
  const touch = event.touches?.length > 0 ? event.touches[0] : event;
  // console.log('MOUSE MVOE: ', editorStore.mode)
  if (editorStore.mode === editorModes.objectPointerDown) {
    const obj = {
      "id": uuidv4(),
      "component": editorStore.tmpComponent,
      "left": touch.clientX - editorStore.offsetX,
      "top": touch.clientY - editorStore.offsetY,
      "rotation": 0,
      z: ++editorStore.zCounter,
    }
    editorStore.tmpObject = obj
    editorStore.objects.push(obj)
    editorStore.newObject = true
    editorStore.dragdrop.object = obj
    editorStore.dragdrop.startLeft = obj.left
    editorStore.dragdrop.startTop = obj.top
    // editorStore.dragdrop.objectStartLeft = (obj.left - editorStore.offsetX) / editorStore.scale - editorStore.tmpComponent.width / 2.0 * 25
    // editorStore.dragdrop.objectStartTop = (obj.top - editorStore.offsetY) / editorStore.scale - editorStore.tmpComponent.height / 2.0 * 25
    editorStore.mode = editorModes.objectMove

    editorStore.selected = [obj]

    editorStore.dragdrop.objectsStartLeft = []
    editorStore.dragdrop.objectsStartTop = []



    for (const object of editorStore.selected) {
      editorStore.dragdrop.objectsStartLeft.push(
        (object.left - editorStore.offsetX) / editorStore.scale - object.component.width / 2.0 * 25
      )
      editorStore.dragdrop.objectsStartTop.push(
        (object.top - editorStore.offsetY) / editorStore.scale - object.component.height / 2.0 * 25
      )
    }

    // for (const object of editorStore.selected) {
    //   editorStore.dragdrop.objectsStartLeft.push(
    //     (object.left)
    //   )
    //   editorStore.dragdrop.objectsStartTop.push(
    //     (object.top )
    //   )
    // }

    console.log('Start coord: ', editorStore.dragdrop.objectsStartLeft, editorStore.dragdrop.objectsStartTop)

    editorStore.tmpComponent = obj.component


  }
  if (editorStore.mode === editorModes.objectMove) {
    let i = 0;
    for (const object of editorStore.selected) {
      let newLeft = editorStore.dragdrop.objectsStartLeft[i] + (touch.clientX - editorStore.dragdrop.startLeft) / editorStore.scale
      let newTop = editorStore.dragdrop.objectsStartTop[i] + (touch.clientY - editorStore.dragdrop.startTop) / editorStore.scale
      // console.log('!$ X', object.left, editorStore.dragdrop.objectsStartLeft[i], editorStore.scale)
      // console.log('!$ Y', object.top, editorStore.dragdrop.objectsStartTop[i])
      // let newLeft = editorStore.dragdrop.objectsStartLeft[i]
      // let newTop = editorStore.dragdrop.objectsStartTop[i]
      i++
      const cellSize = 25
      newLeft = Math.trunc(newLeft / cellSize) * cellSize
      newTop = Math.trunc(newTop / cellSize) * cellSize
      object.left = newLeft
      object.top = newTop
    }
    // if (editorStore.dragdrop.object) {
    //
    //   let newLeft = editorStore.dragdrop.objectStartLeft + (touch.clientX - editorStore.dragdrop.startLeft) / editorStore.scale
    //   let newTop = editorStore.dragdrop.objectStartTop + (touch.clientY - editorStore.dragdrop.startTop) / editorStore.scale
    //
    //   const cellSize = 25
    //
    //   // newLeft = newLeft - editorStore.offsetX
    //   // newTop = newTop - editorStore.offsetY
    //
    //   newLeft = Math.trunc(newLeft / cellSize) * cellSize
    //   newTop = Math.trunc(newTop / cellSize) * cellSize
    //
    //   editorStore.dragdrop.object.left = newLeft
    //   editorStore.dragdrop.object.top = newTop
    // }
  }

  if (editorStore.mode === editorModes.editorMove) {
    editorStore.offsetX = touch.clientX - editorStore.dragdrop.startLeft
    // editorStore.offsetX += touch.movementX
    editorStore.offsetY = touch.clientY - editorStore.dragdrop.startTop
    // editorStore.offsetY += touch.movementY
  }

  if (editorStore.mode === editorModes.editorPointerDown) {

    if (event.ctrlKey) { // Выделение рамкой
      editorStore.mode = editorModes.selectObjects
      // editorStore.selectionWindow.left = (touch.clientX - editorStore.offsetX) / editorStore.scale
      // editorStore.selectionWindow.top = (touch.clientY - editorStore.offsetY) / editorStore.scale
      editorStore.selectionWindow.left = (touch.clientX)
      editorStore.selectionWindow.top = (touch.clientY)
      console.log('Sindow selectipn X: ', editorStore.selectionWindow.left)
    } else { // Просто перемещение
      editorStore.mode = editorModes.editorMove
      editorStore.dragdrop.startLeft = touch.clientX - editorStore.offsetX
      editorStore.dragdrop.startTop = touch.clientY - editorStore.offsetY
    }
  }

  if (editorStore.mode === editorModes.selectObjects) {

    editorStore.selectionWindow.width = touch.clientX - editorStore.selectionWindow.left
    editorStore.selectionWindow.height = touch.clientY - editorStore.selectionWindow.top
  }


  // bgPosXOffset.value += event.movementX
  // bgPosYOffset.value += event.movementY
}

const mouseUpHandler = (event) => {
  if (editorStore.mode === editorModes.objectMove) {
    editorStore.mode = null
    editorStore.movingObject = null
  }
}


// const clickHandler = (event) => {
//   palleteStore.form.selected = null
//   editorStore.selected = null
// }

const handleEditorPointerDown = (event) => {
  if (event.button === 0) {
    editorStore.mode = editorModes.editorPointerDown
  }
}


const handleWheel = (event) => {
  editorStore.scale += -(event.deltaY / 120.0) * 0.1
  if (editorStore.scale < 0.1) {
    editorStore.scale = 0.1
  }
  if (editorStore.scale > 3) {
    editorStore.scale = 3
  }
  console.log('wheel: ', event)
}


onMounted(() => {
  window.addEventListener("pointermove", mouseMoveHandler)
  window.addEventListener("touchmove", mouseMoveHandler)
  window.addEventListener("pointerup", mouseUpObject)
  window.addEventListener("touchend", mouseUpObject)
  window.addEventListener("keydown", handleSpace)
  window.addEventListener("wheel", handleWheel)
})


onUnmounted(() => {
  window.removeEventListener("pointermove", mouseMoveHandler)
  window.removeEventListener("touchmove", mouseMoveHandler)
  window.removeEventListener("pointerup", mouseUpObject)
  window.removeEventListener("touchend", mouseUpObject)
  window.removeEventListener("keydown", handleSpace)
  window.removeEventListener("wheel", handleWheel)
})
</script>

<style scoped>

.editor {
  /* Сетка */
  /*background-image: linear-gradient(to right, rgba(52, 54, 54, 0.25) 1px, transparent 1px),*/
  /*linear-gradient(to bottom, rgba(52, 54, 54, 0.25) 1px, transparent 1px),*/
  /*linear-gradient(to right, rgba(52, 54, 54, 0.35) 2px, transparent 1px),*/
  /*linear-gradient(to bottom, rgba(52, 54, 54, 0.35) 2px, transparent 1px),*/
  /*linear-gradient(to right, rgba(52, 54, 54, 0.0) 1px, transparent 1px),*/
  /*linear-gradient(to bottom, rgba(52, 54, 54, 0.0) 1px, transparent 1px);*/
  /*background-size: 25px 25px,*/
  /*25px 25px,*/
  /*calc(25px * 16) calc(25px * 16),*/
  /*calc(25px * 16) calc(25px * 16),*/
  /*20px 20px,*/
  /*20px 20px;*/
  /*background-position: calc(25px * 4) calc(25px * 5);*/

}

.editor-circles {
  /*background-image: radial-gradient(circle, rgb(var(--fg), 0.8) 4px, transparent 2.5px);*/
  /*background-size: calc(25px * 16) calc(25px * 16);*/
  /*background-position: calc(25px * (4 - 8) + 1px) calc(25px * (5 - 8) + 1px);*/
}

.editor-item {

}

.editor-item > .editor-item-remove {
  visibility: hidden;
}

.editor-item:hover > .editor-item-remove {
  visibility: visible;
}

.modal {
  display: none; /* Скрыть по умолчанию */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 300px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


</style>