import {ofetch} from "ofetch";
import {useAuthStore} from "@/stores/useAuthStore";
import {useRouter} from "vue-router/dist/vue-router";
import {useRoute} from "vue-router";

export const useFetch = () => {

  // const baseURL = 'https://rybinsk-recreation.ru'
  const authStore = useAuthStore()
  const route = useRoute()
  const router = useRouter()
  // const baseURL = 'http://localhost:8000'
  //  const baseURL = 'https://platform-kit.katskov.tech'
 const baseURL = 'https://prod.platform-kit.katskov.tech'
  // const apiURL = `${baseURL}`
//   const baseURL = 'http://platform-kit.katskov.tech:8000'
  // const baseURL = 'http://192.168.31.36:8000'
  // const baseURL = 'http://127.0.0.1:8000'
  // const baseURL = window.location.origin === 'http://127.0.0.1:8080' ? 'http://127.0.0.1:8000' : window.location.origin
  const apiURL = `${baseURL}`
  const __request = async (url, options) => {
    const onResponseError = async ({ request, response, options }) => {
      console.log('ON REQUEST: ', request, options)
      if (response.status === 401) {
        await router.replace(`/admin/login?next=${window.location.href}`)
      }
    }
    return await ofetch(url, {baseURL: apiURL, ...options}, );

    // return await ofetch(url, {baseURL, onResponseError, ...options}, ).catch((err) => err);
  }

  const request = async (url, options) => {
    return await __request(url, options)
  }

  const authRequest = async (url, options) => {
    const headers = {
      'Authorization': `Bearer ${authStore.getToken()}`
    }
    return await __request(url, {headers, ...options})
    // return await ofetch(url, {baseURL, headers, ...options}, )
  }

  return {
    request,
    baseURL,
    authRequest,
  }
}