<template>
  <main class="fixed h-screen w-screen editor-page select-none">
    <popup
      v-model="popUpsStore.cartRedirect.visible"
      @confirm="toTildaShop"
    >
      <template #title>
        Пустая корзина!</template>
      <template #content>
        <p>
          Вы не разместили ни одного компонента на рабочей области. Ваш заказ не содержит компонентов, все равно перейти в интернет-магазин «СВЧ КИТ»?»
        </p>
      </template>
    </popup>
    <popup
      v-model="popUpsStore.tildaSyncResult.visible"
    >
      <template #title>
        <span v-if="popUpsStore.tildaSyncResult.count >= 0">Синхронизация завершена</span>
        <span v-else>Ошибка синхронизации</span>

      </template>
      <template #content>
        <p v-if="popUpsStore.tildaSyncResult.count >= 0">
          Синхронизировано {{popUpsStore.tildaSyncResult.count}} компонентов
        </p>
        <p v-else>
          При синхронизации возникла ошибка на сервере
        </p>
      </template>
      <template #actions>
        <span></span>
      </template>
    </popup>
    <popup
      v-model="popUpsStore.cartRedirectPortValid.visible"
      @confirm="toTildaShop"
    >
      <template #title>Некорректные соединения</template>
      <template #content>
        <p>
          В вашем проекте есть неверно подключенные или неподключенные блоки, все равно хотите перейти в корзину на сайте «СВЧ КИТ»?
        </p>
      </template>
    </popup>
    <popup
      v-model="popUpsStore.notAvailableComponents.visible"
      @confirm="toTildaShop"
    >
      <template #title>Нет в наличии</template>
      <template #content>
        <p>
          Вы добавили позиции
        </p>
        <ul>
          <li v-for="component in editorStore.notAvailableComponents" :key="component.id">- {{ component.name }}</li>
        </ul>
        <p>в данный момент они недоступны для продажи в магазине. Но они доступны под заказ, пожалуйста свяжитесь с нами</p>
        <br>
<!--        <p style="background-color: red; color: white" class="px-3">-->
<!--          Сюда можно поместить контакты-->
<!--        </p>-->
      </template>
    </popup>
    <div v-if="popUpsStore.saveProject.visible">
      <SaveProjectPopup></SaveProjectPopup>
    </div>
    
    <div v-if="isMobile" class="bg-[#177e6c] flex items-center justify-center min-h-screen m-0">
      <MobileOnly></MobileOnly>
    </div>
    <div v-else class="w-full px-[15px] py-[0.1px]">
      <Editor></Editor>
      <NavBar></NavBar>
    </div>
    <div
      id="editor-selection-window"
      class="selection-window"
      :style="{
        left: editorStore.selectionWindow.left + 'px',
        top: editorStore.selectionWindow.top + 'px',
        width: editorStore.selectionWindow.width + 'px',
        height: editorStore.selectionWindow.height + 'px',
      }"
    ></div>
  </main>
</template>

<script setup>
import {onMounted, reactive, watch, computed, ref, onBeforeMount, isMemoSame} from "vue";
import Editor from "@/components/Editor";
import NavBar from "@/components/NavBar";
import MobileOnly from "@/components/MobileOnly";
import SaveProjectPopup from "@/components/SaveProjectPopup";
import {usePopUpsStore} from "@/stores/popups";
import { v4 as uuidv4 } from 'uuid';
import Popup from "@/components/popups/Popup";
import {useEditorStore} from "@/stores/editor";

const popUpsStore = usePopUpsStore()
const editorStore = useEditorStore()

onBeforeMount(() => {
  document.title = 'Конструктор заказа';
})

let isMobile = ref(false)
let isTablet = ref(false)

const toTildaShop = () => {
  if (popUpsStore.warningsNumber == 1) {
    const clientId = localStorage.getItem('clientId')
    if (!clientId) {
      alert('Ошибка: Client ID не указан')
    }
    const url = `http://svch-kit.ru/b90b0082-d2b8-437b-98cb-c42dce6dd9df?clientId=${clientId}`
    window.open(url, '_blank');
  }

}





function checkDevice() {
      let userAgent = navigator.userAgent.toLowerCase();
      let checkMobile = /iphone|ipod|android.*mobile|windows.*phone|blackberry.*mobile/i.test(userAgent);
      let checkTablet = /ipad|android(?!.*mobile)|windows.*touch|tablet|kindle|silk/i.test(userAgent);
      isMobile.value = checkMobile && !checkTablet; // TODO: вернуть
      isTablet.value = checkMobile;
    }

  checkDevice();
  window.addEventListener("resize", (event) => {
    checkDevice()
  });

  let key = localStorage.getItem('clientId')
  if (!key){
    localStorage.setItem('clientId', uuidv4())
  }


</script>

<style scoped>

.editor-page {
  background: radial-gradient(ellipse at 80% 0%, rgb(250,250,232), rgb(172,172,159));
}

.selection-window {
  position: fixed;
  left: 100px;
  top: 200px;
  width: 50vw;
  height: 50vh;
  z-index: 99;
  pointer-events: none;
}
.selection-window:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  inset: 0;
  background: rgb(var(--selection), 0.25);
  border: 4px dashed rgb(var(--selection));
  border-radius: 9px;
}

</style>